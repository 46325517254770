<template>
  <div class="yimiao">
    <div>
      <div style="margin-bottom: 100px;">
        <van-cell-group>
          <van-field v-model="datafrom.Name"
            required
            label="名称"
            placeholder="请输入名称" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.Mobile"
            label="手机号"
            required
            placeholder="请输入手机号" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.IdCard"
            label="身份证号"
            required
            placeholder="请输入身份证号" />
        </van-cell-group>

        <van-field v-model="datafrom.EduDesc"
          label="学历"
          placeholder="请选择学历"
          @click="shopTypePicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="shopTypePicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="eduCodeList"
            value-key="Name"
            @confirm="shopTypeQr"
            @cancel="shopTypePicker = false" />
        </van-popup>

        <van-cell-group>
          <van-field v-model="datafrom.Post"
            label="岗位"
            placeholder="请输入岗位" />
        </van-cell-group>

        <van-field v-model="datafrom.InDate"
          name="InDate"
          required
          label="入职日期"
          @click="inDateTime = true"
          readonly="readonly"
          placeholder="请选择入职日期" />
        <van-popup v-model="inDateTime"
          position="bottom"
          get-container="body">
          <van-datetime-picker v-model="currentDate"
            show-toolbar
            type="date"
            title="请选择入职日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="inDateTime = false"
            @confirm="onjiezhongFend">
          </van-datetime-picker>
        </van-popup>

        <van-field v-model="datafrom.LeaveDate"
          name="LeaveDate"
          label="离职日期"
          @click="leaveDateTime = true"
          readonly="readonly"
          placeholder="请选择离职日期" />
        <van-popup v-model="leaveDateTime"
          position="bottom"
          get-container="body">
          <van-datetime-picker v-model="leaveDate"
            show-toolbar
            type="date"
            title="请选择离职日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="leaveDateTime = false"
            @confirm="leaveDateFend">
          </van-datetime-picker>
        </van-popup>

        <van-cell-group>
          <van-field v-model="datafrom.LoginName"
            label="账号"
            placeholder="请输入账号" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.LoginPwd"
            label="密码"
            type="password"
            placeholder="请输入密码" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.Remark"
            label="备注"
            placeholder="请输入备注" />
        </van-cell-group>

        <div class="submit"
          style="padding: 10px 7px">
          <span style="color: #646566; font-size: 14px;padding-left: 10px;">照片</span>
        </div>
        <van-uploader v-model="ShopImgList"
          multiple
          class="imgs"
          :max-count="1"
          :after-read="ShopImgRead"
          :before-delete="xcDelete"
          :deletable="true" />

      </div>
      <div style="position: fixed;bottom: 15px;width:100%;">
        <div style="width:100%; text-align: center;">
          <van-button class="button"
            @click="postfrom(1)"
            style="width:95%;border-radius: 5px;"
            type="info">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { WxGetShopTypeList } from "@/api/shop";
import { GetCodeEntrysList } from "@/api/wangge";
import { HGetMyHotelStaff, HSaveHotelStaff } from "@/api/RealInfo";
Vue.use(Toast);
import {
  HSaveHotel,
  GetOrgansNullToken,
  WxReBindShop,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      // 营业执照
      jkImageList: [],
      //商铺图片
      ShopImgList: [],
      OrganList: [],
      ridlist: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      currentDate: new Date(),
      NativeCantonCodedata: [],
      inDateTime: false,
      leaveDateTime: false,
      leaveDate: "",
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        StaffId: 0, //从业人员Id
        HotelId: "",//民宿旅馆Id
        Name: "", // 姓名
        Mobile: "", // 手机号
        IdCard: "", // 身份证号
        EduCode: "", // 学历代码
        Post: "", // 岗位
        Img: "", // 照片
        InDate: "", // 入职日期
        LeaveDate: "", // 离职日期
        LoginName: "", // 账号
        LoginPwd: "", // 密码
        Remark: "", // 备注
      },
      eduCodeList: [],
      shopTypePicker: false, //商铺类型
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      dWshow: false,
      bangdingShow: true,
      bjZuoBiao: "",
      shopAdd: this.$route.query.add,
    };
  },
  created () {
    this.GetOrgansNullToken();
    this.shopXq();
    this.zidianlist();
    this.datafrom.ShopCate = this.$route.query.shopCate;
  },
  methods: {
    shopXq: function () {
      console.log(this.$route);
      if (this.$route.params.add == "0") {
        this.datafrom = {
          StaffId: 0, //从业人员Id
          HotelId: this.$route.params.hotelId,//民宿旅馆Id
          Name: "", // 姓名
          Mobile: "", // 手机号
          IdCard: "", // 身份证号
          EduCode: "", // 学历代码
          Post: "", // 岗位
          Img: "", // 照片
          InDate: "", // 入职日期
          LeaveDate: "", // 离职日期
          LoginName: "", // 账号
          LoginPwd: "", // 密码
          Remark: "", // 备注
        };
      } else if (this.$route.params.add == "1") {
        HGetMyHotelStaff({
          openID: getOpenId(),
          hotelId: this.$route.params.hotelId,
          staffId: this.$route.params.staffId,
        }).then((res) => {
          this.bangdingShow = true;
          if (res.data.code == 0) {
            this.datafrom = res.data.data;
            this.bjZuoBiao = res.data.data.Coordinate;
            this.datafrom.Status = res.data.data.Status + "";
            this.datafrom.OCode = res.data.data.OCode;
            this.datafrom.OrgName = res.data.data.OName;
            this.datafrom.GradeDesc = res.data.data.GradeDesc;
            this.RegionList(res.data.data.OCode);
            // console.log(res.data.data);
            //商铺照片
            if (res.data.data.Imgs != "" && res.data.data.Imgs != null) {
              this.ShopImgLists = res.data.data.Imgs.split(',');
              var arr = [];
              for (let i = 0; i < this.ShopImgLists.length; i++) {
                const e = this.ShopImgLists[i];
                arr.push(e);
              }

              this.ShopImgList = arr;
              console.log(this.ShopImgList);
              this.datafrom.Imgs = res.data.data.Imgs;
            }
            if (res.data.data.LicImg != "") {
              //营业执照
              this.jkImageLists = [res.data.data.LicImg,
              ];
              var arrYe = [];
              this.jkImageLists.forEach(function (imgUrl) {
                arrYe.push({ url: imgUrl });
              });
              this.jkImageList = arrYe;
              this.datafrom.LicImg = res.data.data.LicImg;
            }
            // this.datafrom.ShopTypeKind = res.data.data.ShopTypeKind;
            this.datafrom.Region = res.data.data.RName;
          }
          if (this.$route.query.add == "3") {
            this.bangdingShow = false;
          }
        });
      }
    },
    dwXuanze: function (row) {
      if (row == "1") {
        this.dWshow = true;
      } else {
        this.dWshow = false;
        this.Coordinate = this.bjZuoBiao;
      }
    },
    // 字典查询
    zidianlist: function () {
      GetCodeEntrysList({ kind: "5" }).then((res) => {
        this.eduCodeList = res.data.data;
      });
    },
    // 确认学历
    shopTypeQr (val) {
      this.datafrom.EduCode = val.Coding;
      this.datafrom.EduDesc = val.Name;
      this.shopTypePicker = false;
    },
    //上传营业执照
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.picurl);
              that.datafrom.LicImg = res.data.picurl;
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除营业执照
    jkDelete (file) {
      this.jkImageList = [];
      this.datafrom.LicImg = "";
    },
    //上传民宿图片
    ShopImgRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              // console.log(res.data.picurl);
              that.datafrom.Imgs = res.data.picurl;
              // that.ImgsList.push(res.data.picurl[0]);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商铺图片
    xcDelete (file, detail) {
      // this.ShopImgList = [];
      this.datafrom.Imgs = "";
    },
    //入职日期 确认
    onjiezhongFend (val) {
      this.datafrom.InDate = this.dateformat(val);
      this.inDateTime = false;
    },
    //离职日期 确认
    leaveDateFend (val) {
      this.datafrom.LeaveDate = this.dateformat(val);
      this.leaveDateTime = false;
    },
    // 提交
    postfrom: function (row) {
      if (this.datafrom.Name == "") {
        Toast.fail("请输入名称");
        return false;
      }
      if (this.datafrom.Mobile == "") {
        Toast.fail("请输入手机号");
        return false;
      }
      if (this.datafrom.IdCard == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
      if (this.datafrom.InDate == "") {
        Toast.fail("请选择入职日期");
        return false;
      }
      this.datafrom.OpenID = getOpenId();

      HSaveHotelStaff(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
            // this.$router.push({
            //   name: "register",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });

    },
    gitocodelist () {
      GetOrgansNullToken().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.go(-1);
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },

    // 消防器材年检日期
    ondatafromOutTime (val) {
      this.datafrom.YearCheckTime = this.dateformat(val);
      this.datafromOutTime = false;
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      // return `${year}-${month}-${day} ${houser}:${Minutes}`;
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}

.yimiao .inputFrom {
  margin: 15px 8%;
}

.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}

.yimiao .buttons {
  text-align: center;
}

.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}

.buttons {
  text-align: center;
}

.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>